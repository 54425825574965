<template>
  <div class="hd-record special-shift">
    <VContainer fluid>
      <VForm>
        <VContainer>
          <VRow>
            <VCol cols="6">
              <VCard>
                <div class="subtitle-2 text-center">新增追蹤事項</div>
                <VDivider />
                <div class="caption">跌倒評估</div>
                <VRow>
                  <VCol cols="auto">
                    <VBtn text small color="secondary">加入</VBtn>
                  </VCol>
                  <VCol cols="2">
                    <VTextField dense hide-details label="日期" value="2021/08/07" />
                  </VCol>
                  <VSpacer />
                  <VCol cols="2">
                    <VTextField dense hide-details label="開始紀錄者" value="◯◯◯" />
                  </VCol>
                  <VCol cols="2">
                    <VTextField dense hide-details label="完成紀錄者" value="◯◯◯" />
                  </VCol>
                </VRow>
                <div class="caption">預檢查項目</div>
                <VRow>
                  <VCol cols="auto">
                    <VBtn text small color="secondary">加入</VBtn>
                  </VCol>
                  <VCol cols="3">
                    <VTextField dense hide-details label="日期時間" value="2021/08/07 15:00" />
                  </VCol>
                  <VCol>
                    <VTextField dense hide-details label="報告結果" value="結果結果結果" />
                  </VCol>
                  <VCol cols="2">
                    <VTextField dense hide-details label="開始紀錄者" value="◯◯◯" />
                  </VCol>
                  <VCol cols="2">
                    <VTextField dense hide-details label="完成紀錄者" value="◯◯◯" />
                  </VCol>
                </VRow>
                <div class="caption">預抽血項目</div>
                <VRow>
                  <VCol cols="auto">
                    <VBtn text small color="secondary">加入</VBtn>
                  </VCol>
                  <VCol cols="3">
                    <VTextField dense hide-details label="日期時間" value="2021/08/07 15:00" />
                  </VCol>
                  <VCol>
                    <VTextField dense hide-details label="報告結果" value="結果結果結果" />
                  </VCol>
                  <VCol cols="2">
                    <VTextField dense hide-details label="開始紀錄者" value="◯◯◯" />
                  </VCol>
                  <VCol cols="2">
                    <VTextField dense hide-details label="完成紀錄者" value="◯◯◯" />
                  </VCol>
                </VRow>
                <div class="caption">請給病患月報告</div>
                <VRow>
                  <VCol cols="auto">
                    <VBtn text small color="secondary">加入</VBtn>
                  </VCol>
                  <VCol cols="2">
                    <VTextField dense hide-details label="日期" value="2021/08/07" />
                  </VCol>
                  <VSpacer />
                </VRow>
                <div class="caption">其他</div>
                <VRow>
                  <VCol cols="auto">
                    <VBtn text small color="secondary">加入</VBtn>
                  </VCol>
                  <VCol cols="2">
                    <VTextField dense hide-details label="日期" value="2021/08/07" />
                  </VCol>
                  <VCol>
                    <VTextField dense hide-details label="內容" value="內容內容內容內容內容內容" />
                  </VCol>
                </VRow>
              </VCard>
            </VCol>
            <VCol cols="6">
              <VCard>
                <div class="subtitle-2 text-center">正在追蹤事項</div>
                <VDivider />
                <VTabs vertical>
                  <VTab>鐵劑施打</VTab>
                  <VTab>跌倒評估</VTab>
                  <VTab>預檢查項目</VTab>
                  <VTab>預抽血項目</VTab>
                  <VTab>請給病患月報告</VTab>
                  <VTab>其他</VTab>
                  <VTabItem>
                    <VList dense>
                      <template v-for="v in $store.state.fe">
                        <VListItem v-for="(f, j) in v.dates" :key="j">
                          <VListItemContent>
                            <VListItemTitle>
                              {{ f.toLocaleString('zh-TW', { dateStyle: 'full' }) }}
                            </VListItemTitle>
                            <VListItemSubtitle>
                              開始紀錄者：◯◯◯、劑量：{{ v.volume }}
                            </VListItemSubtitle>
                          </VListItemContent>
                          <VListItemAction>
                            <VRow>
                              <VCol>
                                <VBtn icon>
                                  <VIcon color="secondary">mdi-pencil</VIcon>
                                </VBtn>
                              </VCol>
                              <VCol>
                                <VBtn icon>
                                  <VIcon color="success">mdi-check</VIcon>
                                </VBtn>
                              </VCol>
                              <VCol>
                                <VBtn icon>
                                  <VIcon color="error">mdi-delete</VIcon>
                                </VBtn>
                              </VCol>
                            </VRow>
                          </VListItemAction>
                        </VListItem>
                      </template>
                    </VList>
                  </VTabItem>
                  <VTabItem v-for="i in 5" :key="i">
                    <VList dense>
                      <VListItem v-for="j in 3" :key="j">
                        <VListItemContent>
                          <VListItemTitle>2021/08/07</VListItemTitle>
                          <VListItemSubtitle>開始紀錄者：◯◯◯</VListItemSubtitle>
                        </VListItemContent>
                        <VListItemAction>
                          <VRow>
                            <VCol>
                              <VBtn icon>
                                <VIcon color="success">mdi-check</VIcon>
                              </VBtn>
                            </VCol>
                            <VCol>
                              <VBtn icon>
                                <VIcon color="error">mdi-delete</VIcon>
                              </VBtn>
                            </VCol>
                          </VRow>
                        </VListItemAction>
                      </VListItem>
                      <VListItem color="success" :input-value="true">
                        <VListItemContent>
                          <VListItemTitle>2021/08/07</VListItemTitle>
                          <VListItemSubtitle>開始紀錄者：◯◯◯</VListItemSubtitle>
                        </VListItemContent>
                        <VListItemAction>
                          <VListItemTitle>已完成</VListItemTitle>
                          <VListItemSubtitle>完成紀錄者：◯◯◯</VListItemSubtitle>
                        </VListItemAction>
                      </VListItem>
                      <VListItem color="error" :input-value="true">
                        <VListItemContent>
                          <VListItemTitle>2021/08/07</VListItemTitle>
                          <VListItemSubtitle>開始紀錄者：◯◯◯</VListItemSubtitle>
                        </VListItemContent>
                        <VListItemAction>
                          <VListItemTitle>已刪除</VListItemTitle>
                          <VListItemSubtitle>完成紀錄者：◯◯◯</VListItemSubtitle>
                        </VListItemAction>
                      </VListItem>
                    </VList>
                  </VTabItem>
                </VTabs>
              </VCard>
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <VCard>
                <div class="subtitle-2 text-center">完成追蹤事項紀錄</div>
                <VDivider />
                <VDataTable dense :headers="headers" :items="items" item-class="color" />
              </VCard>
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VContainer>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'HDRecordSpecialShift',
  data: () => ({
    headers: [
      { text: '日期', value: 'date' },
      { text: '事項', value: 'name' },
      { text: '時間', value: 'time' },
      { text: '內容', value: 'text' },
      { text: '開始紀錄者', value: 'beginRecorder' },
      { text: '完成紀錄者', value: 'finishRecorder' },
    ],
    items: [
      {
        date: '2021/08/07',
        name: '鐵劑施打',
        time: '',
        text: '',
        beginRecorder: '◯◯◯',
        finishRecorder: '◯◯◯',
        color: 'success lighten-5',
      },
      {
        date: '2021/08/07',
        name: '跌倒評估',
        time: '',
        text: '',
        beginRecorder: '◯◯◯',
        finishRecorder: '◯◯◯',
        color: 'success lighten-5',
      },
      {
        date: '2021/08/07',
        name: '預檢查項目',
        time: '12:00',
        text: '報告結果報告結果報告結果',
        beginRecorder: '◯◯◯',
        finishRecorder: '已刪除',
        color: 'error lighten-5',
      },
      {
        date: '2021/08/07',
        name: '預抽血項目',
        time: '13:00',
        text: '報告結果報告結果報告結果',
        beginRecorder: '◯◯◯',
        finishRecorder: '◯◯◯',
        color: 'success lighten-5',
      },
      {
        date: '2021/08/07',
        name: '請給病患月報告',
        time: '',
        text: '',
        beginRecorder: '◯◯◯',
        finishRecorder: '未完成',
        color: '',
      },
      {
        date: '2021/08/07',
        name: '其他',
        time: '',
        text: '內容內容內容內容內容內容',
        beginRecorder: '◯◯◯',
        finishRecorder: '◯◯◯',
        color: 'success lighten-5',
      },
    ],
  }),
  methods: {
    showContextMenu(event) {
      event.preventDefault();
      this.contextMenuIsShown = false;
      this.cursor.x = event.clientX;
      this.cursor.y = event.clientY;
      this.$nextTick().then(() => {
        this.contextMenuIsShown = true;
      });
    },
  },
});
</script>

<style lang="scss">
.hd-record.special-shift {
  .v-toolbar.v-toolbar--dense {
    z-index: 1;
    .v-toolbar__content {
      padding: 0 3px;
      > * {
        margin: 0 3px;
      }
    }
  }

  > .container {
    max-height: calc(100vh - 144px);
    overflow-y: auto;
    padding: 0px;
    .v-form {
      .container {
        max-width: 1200px;
        width: 1200px;
        padding: 8px;
        .row {
          margin: -4px;
          &:not(:last-child) {
            margin-bottom: 4px;
          }
        }

        .col {
          padding: 4px;
        }

        .v-card {
          padding: 8px;
          .v-divider {
            padding: 4px;
          }

          .row {
            margin: -4px;
          }

          .col {
            padding-top: 12px;
            .v-input {
              padding-top: 0px;
            }
          }
        }
      }
    }
  }
}
</style>
